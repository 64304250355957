import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import {IGetNumber} from '@/pages/local-talks-service/types/get-number';
import {IUser} from '@/core/auth/store/state';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {IServiceCommon} from '@/types/service-common';
import {IServiceHeader} from '@/types/service-header';
import DebtTableComponent from '@/pages/debt-previous-months-service/components/debt-table/debt-table.component.vue';
import {
    DebtPreviousMonthsServiceSandbox
} from '@/pages/debt-previous-months-service/sandbox/debt-previous-months-service.sandbox';
import {IPayloadNumber} from '@/types/payload-number';
import {IReceipt} from '@/pages/debt-previous-months-service/types/receipt';
import DebtMobileComponent from '@/pages/debt-previous-months-service/components/debt-mobile/debt-mobile.component.vue';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'debt-previous-months-service-component',
    components: {
        CustomerInfoComponent,
        DebtTableComponent,
        ServiceNameComponent,
        NotDataComponent,
        DebtMobileComponent
    }
})

export default class DebtPreviousMonthsServiceComponent extends mixins(Router) {
    public sandbox = container.resolve(DebtPreviousMonthsServiceSandbox);
    private store = store;
    public select = 0;
    public locale$: any = locale;
    public locale: any = null;
    public requestNumber = true;
    public $refs!: {
        pagination: {
            reset: () => void;
        },
    };

    get totalAmount(): number | null {
        return this.store.getters._getTotalAmount;
    }

    get routerName() {
        return this.store.getters._getServiceComponentName;
    }

    get userData(): IUser {
        if (localStorage.getItem('mainToken')?.length && this.requestNumber) {
            this.getServiceData();
            this.requestNumber = false;
            let payload: IPayloadNumber = {
                token: String(localStorage.getItem('mainToken')),
                service: this.routerName
            };
            this.sandbox.getNumbers(payload);
        }
        return store.getters._getUserFullData;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get receipts(): IReceipt[] {
        return this.store.getters._getDepReceipts;
    }

    get numbers(): IGetNumber[] {
        return this.store.getters._getNumbers;
    }

    get mainToken(): string | null {
        return localStorage.getItem('mainToken');
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    public mounted(): any {
        this.locale$.subscribe((res: any) => {
            if (res && res.debtPreviousMonthsService) {
                this.locale = res.debtPreviousMonthsService;
            }
        });
    }


    public changeSelectNumber(selectNumber: any): void {
        let payload = {
            token: localStorage.getItem('mainToken'),
            number: selectNumber,
        };
        this.sandbox.getDepReceipts(payload);
        this.select = selectNumber;
    }

}
