import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import TableComponent from '@/core/shared/table/table.component.vue';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import AccordionComponent from '@/core/shared/accordion/accordion.component.vue';
import AccordionItemComponent from '@/core/shared/accordion-item/accordion-item.component.vue';

@Options({
    name: 'information-table-component',
    components: {
        TableComponent,
        NotDataComponent,
        AccordionComponent,
        AccordionItemComponent,
    },
    props: {
        info: {
            type: Object
        },
        mobile:{
            type: Object
        }
    }
})

export default class InformationTableComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.informationPhone) {
                this.locale = res.informationPhone;
            }
        });
    }
}
