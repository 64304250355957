<style src="./information-table.component.scss" lang="scss"></style>
<template>
  <div class="information-table mt-30">
    <div class=" d-lg-none d-block">
      <div class="information-phone-card__info">
        <div class="information-phone-card__info-criteria">
          {{ locale.address }}
        </div>
        <div class="information-phone-card__info-reply">
          {{ mobile.street }} {{ mobile.address }}
        </div>
      </div>
      <div class="information-phone-card__info">
        <div class="information-phone-card__info-criteria">
          {{ locale.junction }}
        </div>
        <div class="information-phone-card__info-reply">
          {{ mobile.organizationName }}
        </div>
      </div>
      <div class="information-phone-card__info">
        <div class="information-phone-card__info-criteria">
          {{ locale.contractDate }}
        </div>
        <div class="information-phone-card__info-reply">
          {{ mobile.contractDate }}
        </div>
      </div>
      <div class="information-phone-card__info">
        <div class="information-phone-card__info-criteria">
          {{ locale.provider }}:
        </div>
        <div class="information-phone-card__info-reply">
          {{ mobile.provider }}
        </div>
      </div>
    </div>
    <div class="information-phone-card__info ">
      <accordion-component class="mb-50 mt-30 service-accordion">
        <accordion-item-component class="accordion-child mb-10">
          <template v-slot:title>
            <div class="information-phone-card__info-title">
              {{ locale.service }}
            </div>
          </template>
          <template v-slot:content>
            <div class="information-phone-card__info-services">
              <div class="information-phone-card__info-services-item" v-for="item in info.service" v-html="item">
              </div>
            </div>
          </template>
        </accordion-item-component>
      </accordion-component>

      <div class="information-phone-card__info-payments">
        <div
            v-for="item in info?.debts"
            class="information-phone-card__info-payment">
          <div class="information-phone-card__info-payment-title">
            {{ item.description }}
          </div>
          <div class="information-phone-card__info-payment-mean
                            information-phone-card__info-payment-mean--red">
            {{ item.debt }}
          </div>
        </div>
      </div>
      <div class="information-payment">
        <accordion-component>
          <accordion-item-component class="accordion-child mb-10" v-for="item in info.payment">
            <template v-slot:title>
              <div class="information-payment__title">
                <div class="row">
                  <div class="col-lg-2 col-12">
                    <div class="information-payment__title-type">{{ item.type }}</div>
                  </div>
                  <div class="col-lg-2 col-12">
                    <div class="information-payment__title-calc">{{ locale.calc }}: <span>{{ item.totalCalc }}</span>
                    </div>
                  </div>
                  <div class="col-lg-2 col-12">
                    <div class="information-payment__title-pay">{{ locale.pay }}: <span>{{ item.totalPay }}</span></div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:content>
              <table-component v-if="item.payments" class="mt-19 mb-12">
                <thead>
                <tr>
                  <th rowspan="2">{{ locale.date }}</th>
                  <th>{{ locale.calc }}</th>
                  <th>{{ locale.pay }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="itemPayment in item.payments">
                  <th>{{ itemPayment.date }}</th>
                  <th>{{ itemPayment.calc }}</th>
                  <th>{{ itemPayment.pay }}</th>
                </tr>
                <tr class="result">
                  <th>{{ item.paymentsTotal.description }}</th>
                  <th>{{ item.paymentsTotal.totalCalc }}</th>
                  <th>{{ item.paymentsTotal.totalPay }}</th>
                </tr>
                </tbody>
                <tbody>
                </tbody>
              </table-component>
            </template>
          </accordion-item-component>
        </accordion-component>
      </div>
    </div>
  </div>
</template>
<script src="./information-table.component.ts"></script>
