import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import TableComponent from '@/core/shared/table/table.component.vue';
import {IGetNumber} from '@/pages/local-talks-service/types/get-number';

@Options({
    name: 'local-table-card-component',
    components: {
        TableComponent
    },
    props: {
        numbers: {
            type: Array,
        },
        data: {
            type: Array,
        },
    }
})

export default class DebtTableComponent extends Vue {
    private store = store;
    public numbers: IGetNumber[] = [];
    public locale$: any = locale;
    public locale: any = null;
    public requestDebt = true;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.debtPreviousMonthsService) {
                this.locale = res.debtPreviousMonthsService;
            }
        });
    }

    get totalAmount(): number | null {
        return this.store.getters._getTotalAmount;
    }

    get selectNumber(): any {
        if (this.store.getters._getSelect && this.requestDebt) {
            this.$emit('changeSelectNumber', this.store.getters._getSelect.id);
            this.requestDebt = false;
        }
        return this.store.getters._getSelect;
    }


    public changeSelectNumber(info: any): void {
        this.store.commit('SET_DEFAULT_SELECT', {
            id: info,
            value: info
        });
        this.$emit('changeSelectNumber', this.selectNumber.id);
    }
}
