import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {store} from '@/store';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import InformationPhoneCardComponent
    from '@/pages/information-phone-service/components/information-phone-card/information-phone-card.component.vue';
import InformationTableComponent
    from '@/pages/information-phone-service/components/information-table/information-table.component.vue';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import {container} from 'tsyringe';
import {locale} from '@/assets/db/db.conf.ts';
import {
    InformationPhoneServiceSandbox
} from '@/pages/information-phone-service/sandbox/information-phone-service.sandbox';
import {IPhoneInformation} from '@/pages/information-phone-service/types/phone-information';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import {IServiceCommon} from '@/types/service-common';
import AccordionComponent from '@/pages/information-phone-service/components/accordion/accordion.component.vue';
import AccordionItemComponent
    from '@/pages/information-phone-service/components/accordion-item/accordion-item.component.vue';
import {IInfoNumber} from '@/pages/information-phone-service/types/info-number';
import ServiceSubtitleComponent from '@/core/shared/service-subtitle/service-subtitle.component.vue';
import {IServiceHeader} from '@/types/service-header';
import {IPayments} from '@/pages/information-phone-service/types/payments';
import {CommonSandbox} from '@/sandbox/common.sandbox';
import {Router} from '@/core/mixin/router';


@Options({
    name: 'information-phone-service-component',
    components: {
        CustomerInfoComponent,
        InformationPhoneCardComponent,
        InformationTableComponent,
        ServiceNameComponent,
        NotDataComponent,
        AccordionComponent,
        AccordionItemComponent,
        ServiceSubtitleComponent
    }
})

export default class InformationPhoneServiceComponent extends mixins(Router)  {
    public locale$: any = locale;
    public locale: any = null;
    public service = 1070;
    public sandbox = container.resolve(InformationPhoneServiceSandbox);
    public sandboxCommon: CommonSandbox = container.resolve(CommonSandbox);
    private store = store;
    public select = 0;
    public selectItem = '0';
    public requestNumberInfo = true;
    public width = 0;

    get userData() {
        if (localStorage.getItem('mainToken') && this.requestNumberInfo) {
            this.getServiceData();
            this.sandbox.getNumbers(localStorage.getItem('mainToken'));
        }
        return this.store.getters._getUserFullData;
    }

    get mainToken(): string | null {
        return localStorage.getItem('mainToken');
    }

    get informationPhone(): IPhoneInformation {
        return this.store.getters._getInformationPhone;
    }

    get numbers(): IInfoNumber[] {
        return this.store.getters._getInfoNumbers;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    public getInfoData(number: string, index: any) {
        let payload = {
            token: localStorage.getItem('mainToken'),
            params: {
                number: number,
                pageNumber: 1,
                pageSize: 1
            }
        };
        this.sandbox.getDetailedInfoService(payload);
        this.sandbox.getPayment({
            params: {
                number: number,
                pageNumber: 1,
                pageSize: 1
            }
        });
        this.selectItem = index;
    }

    public mounted() {
        this.width = window.innerWidth;
        window.addEventListener('resize', () => {
            this.width = window.innerWidth;
        });
    }

    public selectNumber(selectNumber: any) {
        this.select = selectNumber;
        let payload = {
            token: localStorage.getItem('mainToken'),
            params: {
                number: selectNumber,
                pageNumber: 1,
                pageSize: 1
            }
        };
        this.sandbox.getDetailedInfoService(payload);
        this.sandbox.getPayment({
            params: {
                number: selectNumber,
                pageNumber: 1,
                pageSize: 1
            }
        });
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.informationPhone) {
                this.locale = res.informationPhone;
            }
        });

    }

}
