import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class InformationPhoneServiceSandbox {
    private store = store;

    constructor() {
    }

    getNumbers(payload: any): void {
        this.store.dispatch('getInfoNumbers', payload);
    }

    getDetailedInfoService(payload: any): any {
       return  this.store.dispatch('getDetailedInfoService', payload);
    }

    getPayment(payload: any): void {
        this.store.dispatch('getPayment', payload);
    }

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }

}