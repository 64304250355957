<style src="./information-phone-service.component.scss" lang="scss"></style>

<template>
  <div class="information-phone-service">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
        </div>
        <div class="col-12">
          <section>
            <customer-info-component :user="userData"/>
          </section>
        </div>
        <div class="col-12">
          <section class=" mb-100">
            <accordion-component
                v-if="width<992"
                class="accordion-main d-lg-none d-block"
                :numbers="numbers"
                :informationPhone="informationPhone"
            />
            <v-card class="accordion-main d-lg-none  d-block" v-if="(numbers.length === 0) && userData.name && width>992">
              <not-data-component class="mt-20">
                Hal-hazırda sizin adınıza aid telefon
                nömrəsi yoxdur.
              </not-data-component>
            </v-card>
            <v-card v-if="width>992" class="accordion-main d-lg-block  d-none">
              <!--              <services-subtitle-component>-->
              <!--                Xidməti seçin-->
              <!--              </services-subtitle-component>-->
              <accordion-component
                  class="accordion-main"
                  :numbers="numbers"
                  :informationPhone="informationPhone"
              />
              <not-data-component class="mt-20" v-if="(numbers.length === 0) && userData.name">
                Hal-hazırda sizin adınıza aid telefon
                nömrəsi yoxdur.
              </not-data-component>
            </v-card>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./information-phone-service.component.ts"></script>
