import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';

@Options({
    name: 'information-phone-card-component',
    props: {
        info: {
            type: Object
        }
    }
})

export default class InformationPhoneCardComponent extends Vue {
    private store = store;
    public locale$: any = locale;
    public locale: any = null;
    public numbers = [];

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.informationPhone) {
                this.locale = res.informationPhone;
            }
        });
    }

    get selectNumber(): any {
        if (this.store.getters._getSelect) {
            this.$emit('selectNumber', this.selectNumber);
        }
        return this.store.getters._getSelect;
    }

    set selectNumber(selectNumber: any) {
        this.store.commit('SET_DEFAULT_SELECT', selectNumber);
    }


    public selectedNumber(): void {
        this.$emit('selectNumber', this.selectNumber);
    }

    public checkIndex(index: number) {
        if (index === 0) {
            return true;
        } else {
            return false;
        }
    }
}
