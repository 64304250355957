<style src="./accordion.component.scss" lang="scss"></style>

<template>
  <div class="accordion">
    <accordion-item-component
        class="mb-20"
        v-for="item in numbers"
        :item="item.isOpen"
        :number="item.number"
        :allDebt="item.allDebt"
    >
      <template v-slot:title>
        <information-phone-card-component
            @click=" clickItem($event,item)"
            :info="item"
        />
      </template>
      <template v-slot:content>
        <information-table-component
            :mobile="item"
            :info="informationPhone"/>
      </template>
    </accordion-item-component>
  </div>
</template>

<script src="./accordion.component.ts"></script>