import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import AccordionItemComponent
    from '@/pages/information-phone-service/components/accordion-item/accordion-item.component.vue';
import InformationPhoneCardComponent
    from '@/pages/information-phone-service/components/information-phone-card/information-phone-card.component.vue';
import InformationTableComponent
    from '@/pages/information-phone-service/components/information-table/information-table.component.vue';
import {IInfoNumber} from '@/pages/information-phone-service/types/info-number';
import {IPhoneInformation} from '@/pages/information-phone-service/types/phone-information';
import {IDebt} from '@/pages/information-phone-service/types/debt';
import {container} from 'tsyringe';
import {
    InformationPhoneServiceSandbox
} from '@/pages/information-phone-service/sandbox/information-phone-service.sandbox';

@Options({
    name: 'accordion-component',
    components: {
        AccordionItemComponent,
        InformationPhoneCardComponent,
        InformationTableComponent,
    },
    props: {
        numbers: {
            type: Array,
            required: true,
        },
        informationPhone: {
            type: Object,
            required: true,
        },
    }

})

export default class AccordionComponent extends Vue {
    public sandbox = container.resolve(InformationPhoneServiceSandbox);
    public numbers: IInfoNumber[] = [];
    public informationPhone: IPhoneInformation = {
        name: null,
        address: 'string | null',
        date: ' string | null',
        provider: ' string | null',
        service: ' string | null',
        junction: ' string | null',
        debts: [],
        payment:[]
    };
    public locale$: any = locale;
    public locale: any = null;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.elements) {
                this.locale = res.elements;
            }
        });
    }

    public $refs!: {
        item: HTMLElement,
    };

    public async selectedNumber(selectNumber: any) {
        let payload = {
            token: localStorage.getItem('mainToken'),
            params: {
                number: selectNumber,
                pageNumber: 1,
                pageSize: 1
            }
        };
        return await this.sandbox.getDetailedInfoService(payload);

    }


    public async clickItem($event: any, select: any) {
        if ($event.currentTarget) {
            this.numbers.forEach((item) => {
                if (item === select) {
                    if (item.isOpen) {
                        item.isOpen = false;
                    } else {
                        this.selectedNumber(item.number).then((res: any) => {
                            if (this.informationPhone.name) {
                                item.isOpen = !item.isOpen;
                            }
                        });
                    }
                } else {
                    item.isOpen = false;
                }
            });
        }
    }
}