<style src="./information-phone-card.component.scss" lang="scss"></style>
<template>
  <div class="information-phone-card">
    <div class="row ">
      <div class="col-12">
        <div class="title d-md-flex">
          <div class="title__number">
            {{ locale.titleNumber }}: <span>{{ info.number }}</span>
          </div>
          <div class=" d-lg-none d-block">
            <v-divider class=" my-30"/>
          </div>
          <div class="title__debt">
            <div class="title__debt-wrapper">
              {{ locale.titleDept }}:<span class="ml-10">{{ info.allDebt }} AZN</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 d-lg-block d-none">
        <div class="information-phone-card__info mt-25">
          <div class="information-phone-card__info-criteria">
            {{ locale.address }}
          </div>
          <div class="information-phone-card__info-reply">
            {{ info.street }} {{ info.address }}
          </div>
        </div>
        <div class="information-phone-card__info mt-25">
          <div class="information-phone-card__info-criteria">
            {{ locale.junction }}
          </div>
          <div class="information-phone-card__info-reply">
            {{ info.organizationName }}
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12  d-lg-block d-none">
        <div class="information-phone-card__info mt-25">
          <div class="information-phone-card__info-criteria">
            {{ locale.contractDate }}
          </div>
          <div class="information-phone-card__info-reply">
            {{ info.contractDate }}
          </div>
        </div>
        <div class="information-phone-card__info mt-25">
          <div class="information-phone-card__info-criteria">
            {{ locale.provider }}:
          </div>
          <div class="information-phone-card__info-reply">
            {{ info.provider }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./information-phone-card.component.ts"></script>
