<style src="./debt-previous-months-service.component.scss" lang="scss"></style>

<template>
  <div class="debt-previous-months">
    <div class="container-fluid v-container">
      <div class="row">
        <div class="col-12">
          <section class="mt-20">
            <service-name-component
                :serviceName="commonService.name"
                :organization="commonService.structureName"
                :img="commonService.file"
                :payment="commonService.paymentRequired"
                :registered="commonService.authRequired"
                :serviceId="commonService.id"
                :structureId="commonService.structureId"
                :headers="commonService.serviceHeader"
            />
          </section>
          <section>
            <customer-info-component :user="userData"/>
          </section>
          <section class="mt-30 mb-100">
            <v-card>
              <debt-table-component
                  :numbers="numbers"
                  :data="receipts"
                  @changeSelectNumber="changeSelectNumber"
                  v-if="numbers?.length"
              />
              <not-data-component class="mt-20" v-if="(!numbers.length) && userData.name">
                Sizin adınıza aid telefon nömrəsi yoxdur
              </not-data-component>
              <not-data-component class="mt-20" v-if="(receipts?.length === 0) && userData.name">
                Adınıza olan telefon nömrəsi üzrə borc məlumatı mövcud deyil
              </not-data-component>
            </v-card>
            <v-card
                v-if="(receipts?.length >0)"
                class="my-20 d-lg-none d-block debt-previous-months__amount-mobile">
              {{ locale.totalAmount }}: <strong class="ml-40"> {{ totalAmount }} AZN</strong>
            </v-card>
            <debt-mobile-component v-if="(receipts?.length >0)" :debs="receipts"/>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./debt-previous-months-service.component.ts"></script>
