import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class DebtPreviousMonthsServiceSandbox {
    private store = store;

    constructor() {
    }

    getNumbers(payload: any): void {
        this.store.dispatch('getNumbers', payload);
    }

    getDepReceipts(payload: any): void {
        this.store.dispatch('getDepReceipts', payload);
    }

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }
    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }

}