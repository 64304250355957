<style src="./debt-table.component.scss" lang="scss"></style>
<template>
  <div class="receipts-table-card">
    <div class="row">
      <div class="col-md-3 col-12">
        <label class="v-label">{{ locale.phone }}</label>
        <v-select
            @select="changeSelectNumber"
            :value="selectNumber.id"
            :options="numbers"/>
      </div>
      <div class="col-12 d-lg-block d-none"
      >
        <div class="local-table-card__table  mt-40">
          <table-component id="table"  v-if="data?.length!==0">
            <thead>
            <tr>
              <th class="text-center">Sıra sayı</th>
              <th class="text-center">{{ locale.date }}</th>
              <th class="text-center">{{ locale.dialTheNumber }}</th>
              <th class="text-center">{{ locale.duration }}</th>
              <th class="text-center">{{ locale.amount }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!selectNumber">Nömrə seçin</tr>
            <tr v-for="(item, index) in data" :key="index">
              <th class="text-center">{{ index+1 }}</th>
              <th class="local-table-card__number text-center">{{ item.date }}</th>
              <th class="text-center">{{ item.dialTheNumber }}</th>
              <th class="text-center">{{ item.duration }}</th>
              <th class="text-center">{{ item.amount }}</th>
            </tr>
            </tbody>
          </table-component>
        </div>
        <v-card dark class="mt-40 debt-previous-months__amount" v-if="data?.length!==0">
          {{locale.totalAmount}}: <strong class="ml-40"> {{ totalAmount }} AZN</strong>
        </v-card>
      </div>

    </div>
  </div>
</template>
<script src="./debt-table.component.ts"></script>
